import httpClient from './httpClient';
const prefix = 'agent'

export default {
  async login(username, password) {
    const { data: token } = await httpClient({ requiresAuth: false }).post('/login-agent', { phone_number: username, password: password})
    if (token) {
      localStorage.setItem('agent', token.token)
      return true
    }
  },
  async profile() {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'agent' }).get(prefix+'/profile')

    return data
  },
  async find_my_user (username) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'agent' }).put(prefix+'/find-user', { username })

    return data
  },
  async add_credit (id, amount) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'agent' }).post(prefix+'/add-credit', { id, amount })

    return data
  },
  async create_user(userObj) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'agent' }).post(prefix+'/create-user', userObj)

    return data
  },
  async create_agent(agentObj) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'agent' }).post(prefix+'/create-agent', agentObj)

    return data
  }
}