<template>
  <div class="dashboard-container">
    <div style="text-align: end">
      <h2 style="margin: 0">สวัสดี Agent, {{nickname}}</h2>
      <h4 style="margin: 0">ยอดเครดิตคงเหลือ: {{number_comma(balance)}} THB</h4>
    </div>
    <div class="table-dashboard">
      <div class="dashboard-content">
        <h3>Agents - รวมยอด: {{sum_agents}} THB</h3>
        <div class="list-container">
          <table style="width: 100%">
            <tr style="text-align: center; border-bottom: 1px solid #fce285;">
              <td>LINE ID</td>
              <td>ชื่อ</td>
              <td>ยอดเครดิต</td>
            </tr>
            <tr class="table-text" v-for="{ line_id, nickname, balance },i  in downline_agents" :key="i.key">
              <td>{{line_id}}</td>
              <td>{{nickname}}</td>
              <td>{{number_comma(balance)}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="dashboard-content">
        <h3>Users - รวมยอด: {{sum_users}} THB</h3>
        <div class="list-container">
          <table style="width: 100%">
            <tr style="text-align: center; border-bottom: 1px solid #fce285;">
              <!-- <td>LINE ID</td> -->
              <td>ชื่อ</td>
              <td>ยอดเครดิต</td>
              <td>ยอดเสีย</td>
            </tr>
            <tr class="table-text" v-for="{ username, balance, loses: { total, lost_history } },i  in users" :key="i.key">
              <!-- <td>{{line_id? line_id:'-'}}</td> -->
              <td>{{username}}</td>
              <td>{{number_comma(balance)}}</td>
              <td style="cursor: pointer" @click="show_lost_history_detail(lost_history)">{{total}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>


    <div class="table-dashboard">
      <div class="dashboard-content">
        <h3>ประวัติทำรายการ</h3>
        <div class="list-container">
          <table style="width: 100%; text-align: center;">
            <tr v-if="!agent_histories.length">
              <td style="font-size: 8px">- ไม่มี -</td>
            </tr>
            <tr v-else v-for="({ action, created_at }, i) in agent_histories" :key="i.key" style="font-size: 12px">
              <td>{{created_at}}</td>
              <td>{{action}}</td>
            </tr>
          </table>
        </div>
      </div>

      
      <div class="dashboard-content">
        <h3>เปิด <select v-model="create_user">
          <option :value="true">User</option>
          <option :value="false">Agent</option>
        </select> ใหม่</h3>
        <div class="list-container regis-input">
            <input v-if="create_user" class="background-panel" type="text" v-model="regis_bank" placeholder="บัญชีธนาคาร" />
            <input class="background-panel" type="text" v-model="regis_phone_number" placeholder="เบอร์โทรศัพท์" />
            <input class="background-panel" type="text" v-model="regis_username" placeholder="ชื่อบัญชี" />
            <input class="background-panel" type="text" v-model="regis_password" placeholder="รหัสผ่าน" />
            <input class="background-panel" type="number" v-model="regis_balance" placeholder="ยอดเงิน" />
            <button class="highlight-primary th" @click="create_user_agent()">สร้างบัญชี</button>
        </div>
      </div>

      <button id="modal_trigger" style="display: none" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#lost_history_modal"></button>

      <!-- Modal -->
      <div class="modal fade" id="lost_history_modal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="lost_history_modalLabel">Modal title</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" ></button>
            </div>
            <div class="modal-body">
              <span v-for="(list, i) in Modal" :key="i.key">{{list}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import AgentService from '../../services/AgentService'
export default {
  name: 'dashboard-agent',
  computed: {
    sum_agents() {
      return this.number_comma(this.downline_agents.length ? this.downline_agents.map(({ balance }) => balance).reduce((a, b) => a + b) : 0)
    },
    sum_users() {
      return this.number_comma(this.users.length ? this.users.map(({ balance }) => balance).reduce((a, b) => a + b) : 0)
    },
  },
  data () {
    return {
      profile: 'Admin',
      nickname: '',
      balance: 0,
      new_credit: 0,
      users: [],
      downline_agents: [],
      agent_histories: [],

      /* Register */
      create_user: false,
      regis_bank: '',
      regis_phone_number: '',
      regis_username: '',
      regis_password: '',
      regis_balance: '',

      /* Modal */
      Modal: []
    }
  },
  methods: {
    show_lost_history_detail(list) {
      var result = list.map(e => `${e}<br>`)
      result = result.toString().replaceAll(',','')
      Swal.fire({
        title: 'ประวัติการเล่น',
        html: result,
        showConfirmButton: false,
        showCloseButton: true
      })
    },
    async create_user_agent() {
      const userObj = {
        bank: this.regis_bank,
        phone_number: this.regis_phone_number,
        username: this.regis_username,
        password: this.regis_password,
        balance: this.regis_balance
      }
      const agentObj = {
        phone_number: this.regis_phone_number,
        nickname: this.regis_username,
        password: this.regis_password,
        balance: this.regis_balance
      }
      
      var res;
      if (this.create_user) {
        res = await AgentService.create_user(userObj)
      } else {
        res = await AgentService.create_agent(agentObj)
      }
      if (res) {
        this.get_agent_profile()
        alert('สำเร็จ')
        this.regis_bank = ''
        this.regis_phone_number = ''
        this.regis_username = ''
        this.regis_password = ''
        this.regis_balance = ''
        this.create_type = 'User'
      }
    },
    number_comma(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    async search_user() {
      if (this.find_user_input) {
        const { user } = await AgentService.find_my_user(this.find_user_input)
        this.found_user = user? user:false
      }
    },
    async get_agent_profile() {
      const { nickname, users, downline_agents, balance, agent_histories } = await AgentService.profile()
      this.nickname = nickname
      this.users = users
      this.downline_agents = downline_agents
      this.balance = balance
      this.agent_histories = agent_histories
    },
    async add_credit(id, amount) {
      const res = await AgentService.add_credit(id, amount)
      console.log(res);
    },
    check_agent() {
      if (!localStorage.getItem('agent')) {
        this.$router.push('/')
      }
    },
  },
  async mounted () {
    this.check_agent()
    await this.get_agent_profile()
  }
}
</script>

<style lang="less" scoped>
  .regis-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    input {
      margin: 0.25rem
    }
    input::placeholder {
      color: #fce285
    }
  }
  .dashboard-container {
    padding: 0.5rem;
    flex-direction: column;
    display: flex;
  }
  .dashboard-content {
    width: 100%;
    padding: 0.5rem;
  }
  .table-dashboard {
    display: flex;
    justify-content: space-evenly;

    table {
      max-height: 20rem;
      padding: 0.5rem
    }

    .list-container {
      .table-text {
        text-align: center;
        font-size: 14px
      }
      padding: 0.5rem;
      height: 20rem;
      overflow-y: scroll;
      border: 1px solid #fce285;
      border-radius: 5px;
    }
  }
  .create-account {
    display:flex;
    justify-content: center;
    width: 100%;
    .login-input {
      display: flex;
      flex-direction: column;
    }
  }
</style>
